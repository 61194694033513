import React, { useContext, useEffect, useState } from "react";
import { add } from "../../../components/assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../context/Store";

import styled from "styled-components";
import Loader from "../../../components/loader/Loader";
import NodataFound from "../../includes/nodata/NodataFound";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import api from "../../../api";
import Swal from "sweetalert2";
import { FormControl, MenuItem, Select } from "@mui/material";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getYYMMDD } from "../../../utils/functions/function";

function ClaimedInsurances() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("id");

    const [getData, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [render, setRender] = useState(true);
    const [active, setActive] = useState("");
    const [getInsurnceID, setInsuranceID] = useState("");
    const [societies, setSocieties] = useState([]);
    const [selectedSociety, setSelectedSociety] = useState(null);
    const [pagination, setPagination] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEnddate] = useState("");
    const [isPrintLoading, setIsPrintLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [total_amounts, set_total_amounts] = useState({
        total_claim_amount: 0,
        total_premium_amount: 0,
    });

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage + 1);
    }

    const navigate = useNavigate();
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);

    //get accesstoken from store
    const token = user_data.access_token;
    const role = user_data.roles;

    useEffect(() => {
        dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
                active_menu: "Claimed Insurances",
            },
        });
    }, []);

    //fInsurance listing
    useEffect(() => {
        setIsLoading(true);
        let url = "activities/list-claimed-insurance/";
        api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    society: selectedSociety,
                    page: currentPage,
                    start_date: getYYMMDD(startDate),
                    end_date: getYYMMDD(endDate),
                },
            })
            .then((res) => {
                if (res.app_data.StatusCode === 6000) {
                    setIsDisabled(false);
                    setData(res.app_data.data);
                    set_total_amounts({
                        total_claim_amount: res.app_data.total_claim_amount,
                        total_premium_amount: res.app_data.total_premium_amount,
                    });
                    setIsLoading(false);
                    setPagination(res.app_data.pagination_data);
                } else {
                    setIsLoading(false);
                    setData([]);
                    setPagination(null);
                    setIsDisabled(true);
                }
            }).catch(err => {
                setIsDisabled(true);
            })
    }, [active, render, selectedSociety, currentPage, startDate, endDate]);


    useEffect(() => {
        api
            .get(`accounts/society-names/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.app_data.StatusCode === 6000) {
                    setSocieties(res.app_data.data);
                } else {
                    setSocieties([]);
                }
            });
    }, []);

    const customStyles = {
        width: "100px",
        height: "30px",
        borderRadius: "8px",
        // Add any other custom styles here if needed
    };

    const CustomInput = ({ value, onClick }) => (
        <input
            type="text"
            value={value}
            onClick={onClick}
            style={{ width: "100px", height: "30px" }}
        />
    );

    const handleDownload = async () => {
        setIsPrintLoading(true);
        try {
            let apiUrl = `https://api.jeevamilk.com/api/v1/activities/list-claimed-insurance/`;

            // Construct query parameters
            const params = new URLSearchParams();
            if (startDate) params.append("start_date", getYYMMDD(startDate));
            if (endDate) params.append("end_date", getYYMMDD(endDate));
            if (selectedSociety) params.append("society", selectedSociety);
            params.append("download", "is_download");

            if (params.toString()) {
                apiUrl += `?${params.toString()}`;
            }

            const response = await fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 404) {
                alert("No data found!");
            }

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Get the HTML content
            const htmlContent = await response.text();

            // Open in new window for native PDF conversion
            const printWindow = window.open("", "_blank");
            printWindow.document.write(`
                    <html>
                        <head>
                            <style>
                                @media print {
                                    @page {
                                        size: A4 landscape;
                                        margin: 10mm;
                                    }
                                }
                            </style>
                            <script>
                                window.onload = function() {
                                    // Delay print to ensure full page load
                                    setTimeout(() => {
                                        window.print();
                                    }, 500);
                                };
                            </script>
                        </head>
                        <body>${htmlContent}</body>
                    </html>
                `);
            printWindow.document.close();
            setIsPrintLoading(false);
        } catch (error) {
            console.error("PDF Generation Error:", error);
            setIsPrintLoading(false);
        }
    };

    return (
        <Container>
            <Top>
                <h3>Claimed Insurances</h3>
                <div className="total-average">
                    <h4>
                        Total Claimed Amount - <span>₹{Number(total_amounts?.total_claim_amount || 0).toFixed(2) || 0}</span>
                    </h4>
                    <h4>
                        Total Premium Amount - <span>₹{Number(total_amounts?.total_premium_amount || 0).toFixed(2) || 0}</span>
                    </h4>
                </div>
                <div className="right">
                    {role === "Plant" && (
                        <div className="" style={{ width: "180px" }}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="society"
                                    notched={false}
                                    id="society"
                                    value={selectedSociety || ""}
                                    onChange={(e) => {
                                        setSelectedSociety(e.target.value);
                                    }}
                                    displayEmpty
                                >
                                    {/* Default "All Society" option */}
                                    <MenuItem value="">
                                        All Society
                                    </MenuItem>

                                    {/* Render societies dynamically */}
                                    {societies?.map((society, i) => (
                                        <MenuItem key={i} value={society?.id}>
                                            {society?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    <DateContainer className="dates">
                        <ButContainer>From</ButContainer>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            required
                            dateFormat="dd/MM/yyyy"
                            customInput={<CustomInput />}
                            style={customStyles}
                        />
                        {/* {showDateInput && <input type="date" />} */}
                        <ButContainer>To</ButContainer>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEnddate(date)}
                            required
                            minDate={startDate}
                            dateFormat="dd/MM/yyyy"
                            customInput={<CustomInput />}
                            style={customStyles}
                        />
                        <FilterButton
                            onClick={() => handleDownload()}
                            disabled={isDisabled}
                            style={{
                                cursor: isDisabled ? "not-allowed" : "pointer",
                                backgroundColor: isDisabled ? "grey" : "#fff",
                            }}
                        >
                            {isPrintLoading ? "Loading..." : "Print"}
                        </FilterButton>
                    </DateContainer>
                </div>
            </Top>
            <div className="parent-scroll">
                <Bottom className="scroll">
                    <TableHead>
                        <TableItem className="bold">Sl No.</TableItem>
                        <TableItem className="bold">Date of Insurance</TableItem>
                        <TableItem className="bold">Insurance ID</TableItem>
                        <TableItem className="bold">Policy No</TableItem>
                        <TableItem className="bold">Farmer name</TableItem>
                        <TableItem className="bold">Tag No</TableItem>
                        <TableItem className="bold">Society Name</TableItem>
                        <TableItem className="bold">Premium Amount</TableItem>
                        <TableItem className="bold">Claimed Amount</TableItem>
                        <TableItem className="bold">Claimed Date</TableItem>
                    </TableHead>
                    <CoverContainer>
                        {isLoading ? (
                            <Loader />
                        ) : getData.length > 0 ? (
                            getData.map((item, index) => (
                                <TableContent
                                    key={index}
                                    onClick={() => setInsuranceID(item.id)}
                                >
                                    <TableItem>{index + 1}</TableItem>
                                    <TableItem>{item.date_of_insurance}</TableItem>
                                    <TableItem>{item.code || "--"}</TableItem>
                                    <TableItem>{item.policy_number}</TableItem>
                                    <TableItem>{item.farmer_name}</TableItem>
                                    <TableItem>{item.tag_no}</TableItem>
                                    <TableItem>{item.society_name}</TableItem>
                                    <TableItem>{item.premium_amount}</TableItem>
                                    <TableItem>{item.claim_amount}</TableItem>
                                    <TableItem>{item.claim_approved_date}</TableItem>
                                </TableContent>
                            ))
                        ) : (
                            <NodataFound />
                        )}
                    </CoverContainer>
                </Bottom>
            </div>
            {pagination && pagination?.total_pages > 1 && (
                <PaginationContainer>
                    <PaginationText>
                        Showing Milk Report : {pagination?.first_item} -{" "}
                        {pagination?.last_item} of {pagination?.total_items} Reports
                    </PaginationText>
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pagination?.total_pages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </PaginationContainer>
            )}
        </Container>
    );
}

export default ClaimedInsurances;

const DateContainer = styled.div`
    padding: 15px;
    /* background: #fff; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const ButContainer = styled.div`
	padding: 5px 8px;
	background: #2382d4;
	border-radius: 8px;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
`;

const PaginationText = styled.p`
	color: #898989;
	font-size: 14px;
	@media all and (max-width: 890px) {
		margin-bottom: 10px;
	}
`;


const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0 15px;
	@media all and (max-width: 1380px) {
		padding: 15px 0px;
		margin-right: 40px;
	}
	@media all and (max-width: 1280px) {
		margin-right: 20px;
		padding: 20px 0px;
	}
	@media all and (max-width: 980px) {
		margin-right: 10px;
	}
	@media all and (max-width: 890px) {
		flex-direction: column;
	}
    
`;


const Container = styled.div`
  margin-top: 20px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Top = styled.div`
  margin: 0px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right {
    display: flex;
    align-items: center;
    gap: 18px;
  }
  span {
		color: #2382d4;
		font-family: "inter_bold";
	}
	.total-average {
		display: flex;
		align-items: center;
		gap: 10px;
	}
`;

const Button = styled.div`
  width: 175px;
  height: 40px;
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`;

const Tab = styled(Link)`
  border-top: 1px solid #dfe8ed;
  text-decoration: none;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 1px solid #dfe8ed;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;

  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;

const Bottom = styled.div`
  padding-top: 15px;
`;
const TableHead = styled.div`
  height: 70px;
  background: #f8f8f8;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableItems = styled.div`
  width: 10%;
  color: #000;
  font-size: 18px;
`;

const TableContainer = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const CoverContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
`;

const TableContent = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const EditIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ViewIcon = styled(Link)`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableItem = styled.div`
  color: #747474;
  font-size: 15px;
  display: flex;
  font-family: "inter_regular";
  align-items: center;
  width: 12%;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  &.bold {
    font-family: "inter_medium";
  }
  &.cancel {
    width: 120px !important;

    button {
      width: 100%;
      border: 1px solid #808080;
      border-radius: 6px;
      font-family: "inter_medium";
      color: #ff9e9e;
      cursor: pointer;
      background: #fff;
      padding: 6px 10px;
      /* Add margin for spacing */
      margin-right: 0 /* Adjust this value as needed */
    }
  }
  &.claim {
    width: 120px !important;
    margin-left: 10px;

    span {
      font-family: "";
      color: #017710;
      
    }
    button {
      display: block;
      width: 100%;
      border: 1px solid #808080;
      border-radius: 6px;
      font-family: "inter_medium";
      color: #017710;
      cursor: pointer;
      background: #fff;
      padding: 6px 10px;
    }
      
      
  }

`;

const FilterButton = styled.button`
	border: none;
	outline: none;
	border: 1px solid #2382d4;
	padding: 5px 12px;
	border-radius: 8px;
	position: relative;
`;