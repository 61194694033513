import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../context/Store";
import styled from "styled-components";
import NodataFound from "../../includes/nodata/NodataFound";
import api from "../../../api";
import Loader from "../../../components/loader/Loader";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getYYMMDD } from "../../../utils/functions/function";


function Insurance() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("id");
  const [active, setActive] = useState("");
  const [getInsurnceID, setInsuranceID] = useState("");
  const [getData, setData] = useState("");
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEnddate] = useState("");
  const [selectedSociety, setSelectedSociety] = useState(null);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);

  // Accessing user data from context
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const token = user_data.access_token;
  const role = user_data.roles;

  const handleInsurance = () => {
    api
      .get(
        `activities/list-insurance/`, {
        params: {
          page: currentPage,
          start_date: getYYMMDD(startDate),
          end_date: getYYMMDD(endDate)
        }
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setIsDisabled(false)
        } else {
          setIsDisabled(true)
        }
        setData(res.app_data.data);
        setPagination(res.app_data.pagination_data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    // }
  };

  useEffect(() => {
    handleInsurance();
    // Updating user data in context
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: 'insurance',
        active_sub_menu: "details",
      },
    });
  }, []);

  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      style={{ width: "100px", height: "30px" }}
    />
  );
  const customStyles = {
    width: "100px",
    height: "30px",
    borderRadius: "8px",
    // Add any other custom styles here if needed
  };

  const handleDownload = async () => {
    setIsPrintLoading(true);
    try {


      let apiUrl = `https://api.jeevamilk.com/api/v1/activities/list-insurance/`;

      // Construct query parameters
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", getYYMMDD(startDate));
      if (endDate) params.append("end_date", getYYMMDD(endDate));
      if (selectedSociety) params.append("society", selectedSociety);
      params.append("download", "is_download");

      if (params.toString()) {
        apiUrl += `?${params.toString()}`;
      }

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 404) {
        alert("No data found!");
      }


      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Get the HTML content
      const htmlContent = await response.text();

      // Open in new window for native PDF conversion
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
                        <html>
                            <head>
                                <style>
                                    @media print {
                                        @page {
                                            size: A4 landscape;
                                            margin: 10mm;
                                        }
                                    }
                                </style>
                                <script>
                                    window.onload = function() {
                                        // Delay print to ensure full page load
                                        setTimeout(() => {
                                            window.print();
                                        }, 500);
                                    };
                                </script>
                            </head>
                            <body>${htmlContent}</body>
                        </html>
                    `);
      printWindow.document.close();
      setIsPrintLoading(false);
    } catch (error) {
      console.error("PDF Generation Error:", error);
      setIsPrintLoading(false);
    }
  };



  return (
    <Container>
      <Top>
        <h3>Insurance Details</h3>
        <DateContainer className="dates">
          <ButContainer>From</ButContainer>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            required
            dateFormat="dd/MM/yyyy"
            customInput={<CustomInput />}
            style={customStyles}
          />
          {/* {showDateInput && <input type="date" />} */}
          <ButContainer>To</ButContainer>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEnddate(date)}
            required
            minDate={startDate}
            dateFormat="dd/MM/yyyy"
            customInput={<CustomInput />}
            style={customStyles}
          />
          <FilterButton
            onClick={() => handleDownload()}
            disabled={isDisabled}
            style={{
              cursor: isDisabled ? "not-allowed" : "pointer",
              backgroundColor: isDisabled ? "grey" : "#fff",
            }}
          >
            {isPrintLoading ? "Loading..." : "Print"}
          </FilterButton>
        </DateContainer>
      </Top>
      <div className="parent-scroll">
        <Bottom className="scroll">
          <TableHead>
            <TableItem>Farmer Id</TableItem>
            <TableItem>Insurance ID</TableItem>
            <TableItem>Policy No</TableItem>
            <TableItem>Tag No</TableItem>
            <TableItem>Date</TableItem>
            <TableItem>Expiry Date</TableItem>
            <TableItem>Farmer Name</TableItem>
            <TableItem>Insurance Amount</TableItem>
            <StatusItem>Claim Status</StatusItem>
          </TableHead>
          <CoverContainer>
  {isLoading ? (
    <Loader />
  ) : getData.length > 0 ? (
    getData.map((item, index) => (
      <TableContent key={index} onClick={() => setInsuranceID(item.id)}>
        <TableItem>{item.farmer_id}</TableItem>
        <TableItem>{item.code}</TableItem>
        <TableItem>{item.policy_number}</TableItem>
        <TableItem>{item.tag_no}</TableItem>
        <TableItem>{item.date_of_insurance}</TableItem>
        <TableItem>{item.expiry_date_of_insurance}</TableItem>
        <TableItem>{item.farmer_name}</TableItem>
        <TableItem>{item.claim_amount}</TableItem>
        <StatusTableItem>
  {item.claim_approved_date ? (
    <>
      Claimed on
      <span>{item.claim_approved_date}</span>
    </>
  ) : (
    "Not Claimed"
  )}
</StatusTableItem>

      </TableContent>
    ))
  ) : (
    <NodataFound />
  )}
</CoverContainer>


        </Bottom>
      </div>
    </Container>
  );
}

export default Insurance;


const Container = styled.div`
  margin-top: 20px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Top = styled.div`
  margin: 0px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  width: 175px;
  height: 40px;
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Tab = styled(Link)`
  border-top: 1px solid #dfe8ed;
  text-decoration: none;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 1px solid #dfe8ed;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;

  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;

const Bottom = styled.div`
  padding-top: 15px;
`;
const TableHead = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableItems = styled.div`
  width: 10%;
  color: #000;
  font-size: 18px;
`;

const TableContainer = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const CoverContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow: auto;
`;

const TableContent = styled.div`
  height: 70px;
  background: #fff;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const EditIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteIcon = styled.div`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ViewIcon = styled(Link)`
  color: #747474;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableItem = styled.div`
  color: #747474;
  font-size: 15px;
  display: flex;
  font-family: "inter_regular";
  align-items: center;
  width: 10%;
  white-space: nowrap;
`;

const StatusTableItem = styled.div`
  color: #747474;
  font-size: 12px;
  display: flex;
  flex-direction: column; // Stack elements vertically
  font-family: "inter_regular";
  align-items: center;
  text-align: center; // Center-align the text
  width: 10%;
  white-space: nowrap;

  // Responsive adjustments
  @media (max-width: 768px) {
    font-size: 9px;
    width: 20%;
  }

  @media (max-width: 480px) {
    font-size: 8px;
    width: 30%;
    white-space: normal;
  }
`;



const StatusItem = styled.div`
  color: #747474;
  font-size: 15px;
  display: flex;
  font-family: "inter_regular";
  align-items: center;
  width: 10%;
  white-space: nowrap;
  margin-left: 40px;
`;

const DateContainer = styled.div`
    padding: 15px;
    /* background: #fff; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
`;
const ButContainer = styled.div`
	padding: 5px 8px;
	background: #2382d4;
	border-radius: 8px;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
`;
const FilterButton = styled.button`
	border: none;
	outline: none;
	border: 1px solid #2382d4;
	padding: 5px 12px;
	border-radius: 8px;
	position: relative;
`;
