import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { Context } from "../../../context/Store";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import NewTagModal from "./NewTagModal";
import api from "../../../api";
import { getDDMMYY, getYYMMDD } from "../../../utils/functions/function";
function EditInsurance({ type }) {
  //state
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [noOfEmi, setNoEmi] = useState("");

  const [general, setGeneral] = useState({
    dateOfInsurance: "",
    tagNo: "",
    identificationMark: "",
    height: "",
    race: "",
    age: "",
    policy_number: "",
  });

  const [getYield, setYield] = useState({
    am: "",
    pm: "",
    pdMonth: "",
    marketValue: "",
    premiumAmount: "",
    claimAmount: "",
    expiryDate: "",
    emiAmount: "",
  });

  const [societyId, setSocietyId] = useState("");
  const [getSocieties, setSocieties] = useState([]);
  const [getFarmerId, setFarmerId] = useState("");
  const [getFarmers, setFarmers] = useState([]);
  const [getCows, setCows] = useState([]);
  const [getCowiD, setCowId] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [active, setActive] = useState(type || "all");

  const navigate = useNavigate();
  const onToggle = () => {
    setIsToggled(!isToggled);
  };
  const ModalTabClick = () => {
    setIsModalOpen(true);
  };

  // get accesstoken from store
  const {
    state: { user_data, route_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const path = window.location.pathname;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const showSuccessModal = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Insurance Created Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
  };

   useEffect(() => {
      if (general.dateOfInsurance) {
        const updatedExpiryDate = new Date(general.dateOfInsurance);
        updatedExpiryDate.setFullYear(updatedExpiryDate.getFullYear() + 1); // Add one year
        setYield((prev) => ({
          ...prev,
          expiryDate: updatedExpiryDate,
        }));
      }
    }, [general.dateOfInsurance]);
  

  //payload
  const formData = new FormData();
  formData.append("society", societyId);
  formData.append("farmer", getFarmerId);
  formData.append("cow", getCowiD);

  formData.append("date_of_insurance", getYYMMDD(general.dateOfInsurance));
  formData.append("tag_no", general.tagNo);
  formData.append("identification_mark", general.identificationMark);
  formData.append("height", general.height);
  formData.append("race", general.race);
  formData.append("policy_number", general.policy_number);
  formData.append("age", general.age);

  formData.append("yield_am_month", getYield.am);
  formData.append("yield_pm_month", getYield.pm);
  formData.append("market_value", getYield.marketValue);
  formData.append("premium_amount", getYield.premiumAmount);
  formData.append("claim_amount", getYield.claimAmount);
  formData.append("pd_month", getYield.pdMonth);
  formData.append("expiry_date_of_insurance", getYYMMDD(getYield.expiryDate));
  // formData.append("is_emi", "False");
  formData.append("is_emi", isToggled ? "True" : "False");
  if (isToggled) {
    formData.append("no_of_emi", noOfEmi);
  }
  // formData.append("pm_amount", getYield.pdMonth);

  //creating insurance
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);

    api
      .post(`activities/edit-plant-insurance/${id}/`, formData, {})
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          showSuccessModal();
          setIsButtonLoading(false);
          navigate('/insurance');
        } else {
          setIsButtonLoading(false);
        }
      });
  };

  const handleTabClick = () => {
    // Open the modal when the "New Tag" tab is clicked
    setIsModalOpen(true);
  };

  //society listing
 
  useEffect(() => {
    api
      .get(`accounts/societies/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          list_by:  "all",
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setSocieties(res.app_data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setSocieties([]);
        }
      });
  }, []);

  //farmer listing
  useEffect(() => {
    societyId &&
      api
        .get(`accounts/farmers-list/?society_id=${societyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.app_data.StatusCode === 6000) {
            setFarmers(res.app_data.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setFarmers([]);
          }
        });
  }, [societyId]);

  //cow listing
  useEffect(() => {
    getFarmerId &&
      api
        .get(`accounts/farmer-cow-list/${getFarmerId}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.app_data.StatusCode === 6000) {
            setCows(res.app_data.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setCows([]);
          }
        });
  }, [getFarmerId]);

  const { id } = useParams();
  //signle insrance list
  useEffect(() => {
    api
      .get(`activities/single-plant-insurance/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.app_data.data;
        if (res.app_data.StatusCode === 6000) {
          setSocietyId(data.society);
          setFarmerId(data.farmer);
          setCowId(data.cow);
          setGeneral(() => ({
            ...general,
            dateOfInsurance: data.date_of_insurance,
            tagNo: data.tag_no,
            identificationMark: data.identification_mark,
            height: data.height,
            race: data.race,
            age: data.age,
            policy_number: data.policy_number,
          }));
          setYield(() => ({
            ...getYield,
            am: data.yeild_am_month,
            pm: data.yeild_pm_month,
            pdMonth: data.pd_month,
            marketValue: data.market_value,
            premiumAmount: data.premium_amount,
            claimAmount: data.claim_amount,
            expiryDate: data.expiry_date_of_insurance,
            emiAmount: data.emi_amount,
          }));
          setIsToggled(data.is_emi);
          setNoEmi(data.no_of_emi)
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  }, [id]);

  return (
    <>
      <Title>Edit Insurance</Title>
      <RouteInfo>
        <CoverInfo>
          {/* <RouteTop>
        <TitleRout>Create Rout</TitleRout>
      </RouteTop> */}
          <Form onSubmit={(e) => handleSubmit(e)}>
            <General>General</General>
            <CoverForm>
              <CoverIn>
                <Label>Select Society*</Label>
                <Select
                  value={societyId}
                  onChange={(e) => setSocietyId(e.target.value)}
                >
                  <option value="select">--SELECT--</option>
                  {getSocieties?.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.name}{" "}
                    </option>
                  ))}
                </Select>
              </CoverIn>
              <CoverIn>
                <Label>Society Farmer*</Label>
                <Select
                  value={getFarmerId}
                  onChange={(e) => setFarmerId(e.target.value)}
                >
                  <option value="select">--SELECT--</option>
                  {getFarmers?.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Select>
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>Select Cow*</Label>
                <Select
                  value={getCowiD}
                  onChange={(e) => setCowId(e.target.value)}
                >
                  <option value="select">--SELECT--</option>
                  {getCows?.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Select>
              </CoverIn>
              <CoverIn>
                <Label>Policy No.</Label>
                <Input
                  type="text"
                  value={general.policy_number}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      policy_number: e.target.value,
                    }))
                  }
                />
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>Date of Insurance</Label>
                <InputBox>
                  <DatePicker
                    className="insurance"
                    value={
                      general.dateOfInsurance
                        ? getDDMMYY(general.dateOfInsurance)
                        : "DD-MM-YYYY"
                    }
                    dateFormat="dd-MM-yy"
                    onChange={(date) =>
                      setGeneral(() => ({
                        ...general,
                        dateOfInsurance: date,
                      }))
                    }
                    required
                    name="date_of_insurance"
                    // dateFormat="dd-mm-yy"
                  />
                </InputBox>
              </CoverIn>
              <CoverIn>
                <Label>Tag No</Label>
                <Input
                  type="text"
                  value={general.tagNo}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      tagNo: e.target.value,
                    }))
                  }
                />
              </CoverIn>
            </CoverForm>
            <Tabs>
              <Tab
                to="/taghistory"
                onClick={() => setActive("taghistory")}
                className={active === "taghistory" && "active"}
              >
                Tag History
              </Tab>
              <Tab
                to={{
                  pathname: "",
                }}
                onClick={handleTabClick}
                className={active === "newtag" && "active"}
              >
                New Tag
              </Tab>
            </Tabs>
            <CoverButton>
              <CoverinButton>
                <Label>Identification Mark</Label>
                <Input
                  type="text"
                  value={general.identificationMark}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      identificationMark: e.target.value,
                    }))
                  }
                />
              </CoverinButton>
            </CoverButton>
            <CoverForm>
              <CoverIn>
                <Label>Height(CM)</Label>
                <Input
                  type="number"
                  value={general.height}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      height: e.target.value,
                    }))
                  }
                />
              </CoverIn>
              <CoverIn>
                <Label>Race</Label>
                <Input
                  // type={show ? "text" : "password"}
                  type="text"
                  value={general.race}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      race: e.target.value,
                    }))
                  }
                  required
                />
                {/* <Icon onClick={() => setShow(!show)}>
                  {show ? (
                    <i className="ri-eye-off-line"></i>
                  ) : (
                    <i className="ri-eye-line"></i>
                  )}
                </Icon> */}
              </CoverIn>
            </CoverForm>
            <CoverButton>
              <CoverinButton>
                <Label>Age</Label>
                <Input
                  type="text"
                  value={general.age}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      age: e.target.value,
                    }))
                  }
                />
              </CoverinButton>
            </CoverButton>
            <General className="veh">Yield </General>
            <CoverForm>
              <CoverIn>
                <Label>AM</Label>
                <Input
                  type="text"
                  value={getYield.am}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      am: e.target.value,
                    }))
                  }
                />
              </CoverIn>
              <CoverIn>
                <Label>PM</Label>
                <Input
                  type="text"
                  // className="auto"
                  value={getYield.pm}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      pm: e.target.value,
                    }))
                  }
                />
              </CoverIn>
            </CoverForm>
            <CoverButton>
              <CoverinButton>
                <Label>PD Month</Label>
                <Input
                  type="text"
                  value={getYield.pdMonth}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      pdMonth: e.target.value,
                    }))
                  }
                />
              </CoverinButton>
            </CoverButton>
            <CoverForm>
              <CoverIn>
                <Label>Market Value(RS)</Label>
                <Input
                  type="text"
                  value={getYield.marketValue}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      marketValue: e.target.value,
                    }))
                  }
                />
              </CoverIn>
              <CoverIn>
                <Label>Premium Amount (Rs)</Label>
                <Input
                  type="number"
                  // className="auto"
                  value={getYield.premiumAmount}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      premiumAmount: e.target.value,
                    }))
                  }
                />
              </CoverIn>
            </CoverForm>
            <CoverButton>
              <CoverinButton>
                <Label>Claim Amount (Rs)</Label>
                <Input
                  type="text"
                  value={getYield.claimAmount}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      claimAmount: e.target.value,
                    }))
                  }
                />
              </CoverinButton>
            </CoverButton>
            <CoverinButton>
              <Label>Expiry Date</Label>
              <InputBox>
                <DatePicker
                  className="insurance"
                  value={
                    getYield.expiryDate
                      ? getDDMMYY(getYield.expiryDate)
                      : "DD-MM-YYYY"
                  }
                  dateFormat="dd-MM-yy"
                  onChange={(date) =>
                    setYield(() => ({
                      ...getYield,
                      expiryDate: date,
                    }))
                  }
                  required
                  // dateFormat="dd-mm-yy"
                />
              </InputBox>
            </CoverinButton>
            <Emi>
              <h6>Enable EMI Option</h6>
              <ToggleSwitchWrapper>
                <HiddenCheckbox checked={isToggled} onChange={onToggle} />
                <Switch />
              </ToggleSwitchWrapper>
            </Emi>
            <EmiEnabled enabled={isToggled}>
              <InputLabelemi>No of EMI</InputLabelemi>
              <Input
                type="text"
                required={isToggled}
                name="no_of_emi"
                value={noOfEmi}
                onChange={(e) => setNoEmi(e.target.value)}
              />
            </EmiEnabled>
            <CoverForm></CoverForm>{" "}
            {/* <ErrorMessage>{errorMessage}</ErrorMessage> */}
            <Buttons>
              <Button className="cancel" onClick={() => navigate('/insurance')}>Cancel</Button>
              {isButtonLoading ? (
                <Button>
                  <ButtonLoader />
                </Button>
              ) : (
                <SubmitButton type="submit" value="Update" />
              )}
            </Buttons>
          </Form>
        </CoverInfo>
      </RouteInfo>
      <NewTagModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}
export default EditInsurance;

const RouteInfo = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 30px;
  width: 70%;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: #0a0a0a;
  margin-bottom: 30px;
  font-size: 26px;
  width: 70%;
  margin: 0 auto;
  margin-top: 34px;
  padding-bottom: 34px;
`;
const Button = styled.div`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const SubmitButton = styled.input`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const Form = styled.form``;
const ErrorMessage = styled.span`
  color: red;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const CoverInfo = styled.div``;
const General = styled.h3`
  color: #0a0a0a;
  position: relative;
  overflow: hidden;
  font-size: 22px;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 100px;
    background-color: #ebebeb;
    bottom: 10px;
  }
  &.veh {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 260px;
      bottom: 10px;
      background-color: #ebebeb;
    }
  }
  &.bank {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      right: 0px;
      bottom: 10px;
      background-color: #ebebeb;
      left: unset;
      width: 80%;
    }
  }
  &.other {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      right: 0px;
      bottom: 10px;
      background-color: #ebebeb;
      left: unset;
      width: 75%;
    }
  }
`;

const CoverForm = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;
const CoverButton = styled.div``;
const InputBox = styled.div`
  background-color: #f4f5f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;
  position: relative;
  input {
    background-color: transparent;
    width: 100%;
    font-size: 18px;
  }
`;
const CoverIn = styled.div`
  width: 50%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
  position: relative;
  &.add {
    width: 48%;
    margin-right: 0;
  }
`;
const CoverinForm = styled.div`
  width: 100%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
  position: relative;
  &.add {
    width: 48%;
    margin-right: 0;
  }
`;
const Label = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  &.auto {
    color: #adadad;
  }
`;
const Select = styled.select`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  outline: none;
  &.auto {
    color: #adadad;
  }
`;
const Icon = styled.div`
  color: #292c2d;
  position: absolute;
  right: 20px;
  top: 53px;
  cursor: pointer;
  @media all and (max-width: 400px) {
    right: 20px;
    top: 38px;
  }
`;
const CoverinButton = styled.div`
  width: 100%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
  position: relative;
  &.add {
    width: 48%;
    margin-right: 0;
  }
`;
const Tab = styled(Link)`
  border-top: 1px solid #dfe8ed;
  text-decoration: none;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 1px solid #dfe8ed;
  padding: 9px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 0px 1px 0 0;
  background-color: #f4f5f8;
`;
const Tabs = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Emi = styled.div`
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 20px;
  h6 {
    color: #747474;
    font-family: "inter_regular";
    font-size: 18px;
    margin-right: 16px; /* Adjust as needed */
  }
`;

const EmiEnabled = styled.div`
  display: ${({ enabled }) => (enabled ? "flex" : "none")};
  align-items: center;
  margin-bottom: 30px;
  gap: 30px;
`;

const InputLabelemi = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
  white-space: nowrap;
`;

const ToggleSwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

const Switch = styled.span`
  position: absolute;
  cursor: pointer;
  background-color: #fff;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
  border: 1px solid #000;

  &:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 1px;
    width: 21px;
    height: 21px;
    background-color: #3c3c3c;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  ${HiddenCheckbox}:checked + &::before {
    transform: translateX(25px);
    background-color: #000;
  }

  ${HiddenCheckbox}:checked + & {
    background-color: #e4e4e4;
  }
`;
